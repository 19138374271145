<template>
	<v-data-table
		:loading="loading"
		:headers="headers"
		:items="filteredWizardItems"
		class="elevation-1"
		:footer-props="{
			itemsPerPageAllText: 'Все',
			itemsPerPageText: 'Строк на стр.'
		}"
		:items-per-page="-1"
		loading-text="Загрузка данных"

	>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>Список колдунщиков</v-toolbar-title>
				<v-divider vertical inset class="mx-6"/>
				<v-col cols="6">
					<v-text-field
						outlined
						hide-details
						clearable
						dense
						label="Поиск"
						v-model="searchItem"
					/>
				</v-col>
			</v-toolbar>
		</template>
		<template v-slot:item.seo_api_name="props">
			{{props.item.seo_api_name}}
		</template>
		<template v-slot:item.group_title="props">
			<v-label-editable
				:value="props.item.group_title"
				:options="wizardsGroupList.items"
				returnObject
				itemText="title"
				:rules="[rules.required]"
				@input="(val)=>{
					props.item.group_title = val.title
					putWizardItem({id:props.item.id,group_id:val.id})
				}"
			/>
		</template>
		<template v-slot:item.query_data="props">
			<div class="query-info">
				<span>ПМ: <b>{{props.item.query_data?.source}}</b></span>
				<span>Регион: <b>{{props.item.query_data?.region}}</b></span>
				<span>Запрос: <b>{{props.item.query_data?.query}}</b></span>
				<span>Устройство: <b>{{props.item.query_data?.device}}</b></span>
			</div>
		</template>
		<template v-slot:item.actions="{ item }">
			<v-btn
				icon
				small
				@click="deleteItem(item)"
			>
				<v-icon small>mdi-delete</v-icon>
			</v-btn>
		</template>
		<template v-slot:no-data>
			<v-btn
				color="primary"
				@click="searchItem = ''"
			>
				Сбросить
			</v-btn>
		</template>
	</v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import labelEditable from '@/components/labelEditable'

export default {
	components: {
		'v-label-editable': labelEditable
	},
	data: function () {
		return {
			loading: false,
			dialogDelete: false,
			rules: {
				required: value => !!value || 'Поле обязательно'
			},
			search: undefined,
			headers: [
				{
					text: 'Имя',
					align: 'start',
					sortable: true,
					value: 'seo_api_name',
					width: '30%'
				},
				{
					text: 'Группа',
					sortable: true,
					width: '25%',
					value: 'group_title'

				},
				{
					text: 'Примеры запросов',
					align: 'start',
					sortable: false,
					value: 'query_data'

				}
			],
			editedItem: {
				translate: ''
			},
			searchItem: ''
		}
	},

	computed: {
		...mapGetters({
			wizardsGroupList: 'i18n/wizardsGroupList',
			wizardsList: 'i18n/wizardsList'
		}),
		filteredWizardItems () {
			let res = this.wizardsList.items
			if (this.searchItem) { res = res.filter(it => it.seo_api_name.toLowerCase().includes(this.searchItem.trim())) }
			return res
		}
	},
	watch: {
		dialogDelete (val) {
			if (!val) {
				this.resetEditedItem()
			}
		}
	},
	mounted () {
		if (!this.wizardsGroupList.items.length || !this.wizardsList.items.length) {
			this.initialize()
		}
	},

	methods: {
		...mapActions({
			getWizardsGroupList: 'i18n/getWizardsGroupList',
			getWizardsList: 'i18n/getWizardsList',
			putWizardItem: 'i18n/putWizardItem'
		}),
		async initialize () {
			this.loading = true
			try {
				await this.getWizardsGroupList()
				await this.getWizardsList()
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}

			this.loading = false
		},

		deleteItem (item) {
			this.editedItem = { ...item }
			this.dialogDelete = true
		}
	}
}
</script>

<style lang="scss">
.query-info{
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0px 0px;
	grid-template-areas:
    ". ."
    ". .";
}
</style>
