var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.filteredWizardItems,"footer-props":{
		itemsPerPageAllText: 'Все',
		itemsPerPageText: 'Строк на стр.'
	},"items-per-page":-1,"loading-text":"Загрузка данных"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Список колдунщиков")]),_c('v-divider',{staticClass:"mx-6",attrs:{"vertical":"","inset":""}}),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","clearable":"","dense":"","label":"Поиск"},model:{value:(_vm.searchItem),callback:function ($$v) {_vm.searchItem=$$v},expression:"searchItem"}})],1)],1)]},proxy:true},{key:"item.seo_api_name",fn:function(props){return [_vm._v(" "+_vm._s(props.item.seo_api_name)+" ")]}},{key:"item.group_title",fn:function(props){return [_c('v-label-editable',{attrs:{"value":props.item.group_title,"options":_vm.wizardsGroupList.items,"returnObject":"","itemText":"title","rules":[_vm.rules.required]},on:{"input":(val)=>{
				props.item.group_title = val.title
				_vm.putWizardItem({id:props.item.id,group_id:val.id})
			}}})]}},{key:"item.query_data",fn:function(props){return [_c('div',{staticClass:"query-info"},[_c('span',[_vm._v("ПМ: "),_c('b',[_vm._v(_vm._s(props.item.query_data?.source))])]),_c('span',[_vm._v("Регион: "),_c('b',[_vm._v(_vm._s(props.item.query_data?.region))])]),_c('span',[_vm._v("Запрос: "),_c('b',[_vm._v(_vm._s(props.item.query_data?.query))])]),_c('span',[_vm._v("Устройство: "),_c('b',[_vm._v(_vm._s(props.item.query_data?.device))])])])]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.searchItem = ''}}},[_vm._v(" Сбросить ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }